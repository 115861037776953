import { ViewportScroller } from '@angular/common';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Event, NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { filter, pairwise } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollRestorationService {
  private readonly viewScroller: ViewportScroller = inject(ViewportScroller);
  private readonly router: Router = inject(Router);

  private readonly currentRoute: WritableSignal<string> = signal('');

  public init(): void {
    this.handleRouteChanges();
    this.handleSameRouteNavigation();
  }

  private handleRouteChanges(): void {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        pairwise(),
      )
      .subscribe(([prev, current]) => {
        if (this.getBaseUrl(prev.url) !== this.getBaseUrl(current.url)) {
          this.scrollToTop();
        }
      });
  }

  private handleSameRouteNavigation(): void {
    this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationSkipped))
      .subscribe(event => {
        // console.log();
        // console.log(this.viewScroller.getScrollPosition());

        if (this.hasAnchor(this.currentRoute()) !== this.hasAnchor(event.url)) {
          this.scrollToTop();
        }

        if (event.url !== this.currentRoute()) {
          this.currentRoute.set(event.url);
        } else {
          this.scrollToTop();
        }
      });
  }

  public scrollToAnchor(name: string): void {
    this.viewScroller.scrollToAnchor(`anchor-${name}`);
  }

  public scrollToTop(): void {
    this.viewScroller.scrollToPosition([0, 0]);
  }

  private getBaseUrl(url: string): string {
    return url.split('?')[0];
  }

  private hasAnchor(url: string): boolean {
    return Boolean(url.split('#')[1]);
  }
}
